/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 10:36:28
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-15 15:21:50
 */
import Dialog from './dialog'
import comboForm from './comboForm'
import { numberFormat } from '@/plugins/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'AppAgentTable',
  components: {
    Dialog,
    comboForm
  },
  data () {
    return {
      editName: '',
      queryForm: {
        keyword: '',
        field_type: '',
        sort_type: ''
      }
    }
  },
  methods: {
    tableSearch (reset, sort) {
      if (!sort) {
        this.queryForm.field_type = ''
        this.queryForm.sort_type = ''
      }
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    tableSort (val) {
      console.log(val)
      this.queryForm.field_type = val.fieldType
      this.queryForm.sort_type = val.sort_type
      this.tableSearch(true, true)
    },
    numFormat (val) {
      return numberFormat(val)
    },
    detail (id) {
      this.$router.push({ path: `/admin/agent/detail/${id}` })
    },
    async combo (row) {
      this.editName = row.agent_name
      const data = await this.$wPost('/admin/agent/get_combo.do', { id: row.agent_id })
      if (data !== false) {
        data.id = row.agent_id
        for (const i in data) {
          if (data[i] === null) {
            data[i] = ''
          }
        }
        if (data.pay_money) {
          data.pay_money = data.pay_money / 100
        }
        await this.$refs.comboForm.updatedForm(data)
      }
      this.$refs.comboForm.show()
    },
    async edit (row) {
      const dialog = this.$refs.dialog
      await dialog.updatedForm(row)
      dialog.show()
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  }
}
