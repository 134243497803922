/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-20 10:36:49
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-20 10:44:06
 */
import AgentTable from './AgentTable.vue'
export default AgentTable
